/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FilingStatusEnum } from './FilingStatusEnum';
import {
    FilingStatusEnumFromJSON,
    FilingStatusEnumFromJSONTyped,
    FilingStatusEnumToJSON,
} from './FilingStatusEnum';
import type { PaymentStatusEnum } from './PaymentStatusEnum';
import {
    PaymentStatusEnumFromJSON,
    PaymentStatusEnumFromJSONTyped,
    PaymentStatusEnumToJSON,
} from './PaymentStatusEnum';
import type { TaxIdTypeEnum } from './TaxIdTypeEnum';
import {
    TaxIdTypeEnumFromJSON,
    TaxIdTypeEnumFromJSONTyped,
    TaxIdTypeEnumToJSON,
} from './TaxIdTypeEnum';
import type { CompanyApplicant } from './CompanyApplicant';
import {
    CompanyApplicantFromJSON,
    CompanyApplicantFromJSONTyped,
    CompanyApplicantToJSON,
} from './CompanyApplicant';
import type { TypeOfFilingEnum } from './TypeOfFilingEnum';
import {
    TypeOfFilingEnumFromJSON,
    TypeOfFilingEnumFromJSONTyped,
    TypeOfFilingEnumToJSON,
} from './TypeOfFilingEnum';
import type { BeneficialOwner } from './BeneficialOwner';
import {
    BeneficialOwnerFromJSON,
    BeneficialOwnerFromJSONTyped,
    BeneficialOwnerToJSON,
} from './BeneficialOwner';
import type { ReportNameEnum } from './ReportNameEnum';
import {
    ReportNameEnumFromJSON,
    ReportNameEnumFromJSONTyped,
    ReportNameEnumToJSON,
} from './ReportNameEnum';
import type { JurisdictionEnum } from './JurisdictionEnum';
import {
    JurisdictionEnumFromJSON,
    JurisdictionEnumFromJSONTyped,
    JurisdictionEnumToJSON,
} from './JurisdictionEnum';

/**
 * 
 * @export
 * @interface PatchedBeneficialOwnershipReport
 */
export interface PatchedBeneficialOwnershipReport {
    /**
     * 
     * @type {number}
     * @memberof PatchedBeneficialOwnershipReport
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    readonly filingStatusDisplay?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    readonly jurisdictionDisplay?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    readonly paymentStatusDisplay?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    readonly reportNameDisplay?: string;
    /**
     * 
     * @type {Array<BeneficialOwner>}
     * @memberof PatchedBeneficialOwnershipReport
     */
    beneficialOwners?: Array<BeneficialOwner>;
    /**
     * 
     * @type {Array<CompanyApplicant>}
     * @memberof PatchedBeneficialOwnershipReport
     */
    companyApplicants?: Array<CompanyApplicant>;
    /**
     * 
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    readonly typeOfFilingDisplay?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    readonly taxIdTypeDisplay?: string;
    /**
     * 
     * @type {Date}
     * @memberof PatchedBeneficialOwnershipReport
     */
    readonly createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PatchedBeneficialOwnershipReport
     */
    readonly updatedAt?: Date;
    /**
     * 
     * @type {ReportNameEnum}
     * @memberof PatchedBeneficialOwnershipReport
     */
    reportName?: ReportNameEnum;
    /**
     * 
     * @type {JurisdictionEnum}
     * @memberof PatchedBeneficialOwnershipReport
     */
    jurisdiction?: JurisdictionEnum;
    /**
     * 
     * @type {FilingStatusEnum}
     * @memberof PatchedBeneficialOwnershipReport
     */
    filingStatus?: FilingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    paymentType?: string;
    /**
     * Payment status
     * 
     * * `unpaid` - Unpaid
     * * `pending` - Pending
     * * `paid` - Paid
     * @type {PaymentStatusEnum}
     * @memberof PatchedBeneficialOwnershipReport
     */
    paymentStatus?: PaymentStatusEnum;
    /**
     * The date and time the filing was received by governing agency.
     * @type {Date}
     * @memberof PatchedBeneficialOwnershipReport
     */
    receivedTimestamp?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof PatchedBeneficialOwnershipReport
     */
    filedDate?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof PatchedBeneficialOwnershipReport
     */
    approvedDate?: Date | null;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof PatchedBeneficialOwnershipReport
     */
    completedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    filingNote?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedBeneficialOwnershipReport
     */
    percentComplete?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    emailNotificationStatus?: string;
    /**
     * 
     * @type {TypeOfFilingEnum}
     * @memberof PatchedBeneficialOwnershipReport
     */
    typeOfFiling?: TypeOfFilingEnum;
    /**
     * E.g. 1979-05-12
     * @type {Date}
     * @memberof PatchedBeneficialOwnershipReport
     */
    filingDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    reportingCompanyLegalName?: string;
    /**
     * Alternate name (e.g. trade name, DBA)
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    alternateName?: string;
    /**
     * Check this box if the reporting company is a foreign pooled investment vehicle
     * @type {boolean}
     * @memberof PatchedBeneficialOwnershipReport
     */
    foreignPooledInvestmentVehicle?: boolean;
    /**
     * 
     * @type {TaxIdTypeEnum}
     * @memberof PatchedBeneficialOwnershipReport
     */
    taxIdType?: TaxIdTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    taxIdNumber?: string;
    /**
     * Country of issuance (if Foreign Tax ID Only
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    taxIdCountry?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    countryOfFormation?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    stateOfFormation?: string;
    /**
     * If applicable
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    tribalJurisdiction?: string;
    /**
     * If applicable
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    nameOfTribe?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    businessAddress1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    businessAddress2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    businessCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    businessState?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    businessZip?: string | null;
    /**
     * Enter the 2-letter country code (e.g., US for United States).
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    businessCountry?: string | null;
    /**
     * Is this person an existing reporting company?
     * @type {boolean}
     * @memberof PatchedBeneficialOwnershipReport
     */
    existingReportingCompany?: boolean;
    /**
     * Email address of primary contact
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    contactEmail?: string | null;
    /**
     * Submission Tracking ID
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    submissionTrackingId?: string;
    /**
     * BOIR ID
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    boirId?: string | null;
    /**
     * A copy of the submission transcript
     * @type {string}
     * @memberof PatchedBeneficialOwnershipReport
     */
    submissionTranscript?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedBeneficialOwnershipReport
     */
    readonly team?: number;
}



/**
 * Check if a given object implements the PatchedBeneficialOwnershipReport interface.
 */
export function instanceOfPatchedBeneficialOwnershipReport(value: object): value is PatchedBeneficialOwnershipReport {
    return true;
}

export function PatchedBeneficialOwnershipReportFromJSON(json: any): PatchedBeneficialOwnershipReport {
    return PatchedBeneficialOwnershipReportFromJSONTyped(json, false);
}

export function PatchedBeneficialOwnershipReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedBeneficialOwnershipReport {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'filingStatusDisplay': json['filing_status_display'] == null ? undefined : json['filing_status_display'],
        'jurisdictionDisplay': json['jurisdiction_display'] == null ? undefined : json['jurisdiction_display'],
        'paymentStatusDisplay': json['payment_status_display'] == null ? undefined : json['payment_status_display'],
        'reportNameDisplay': json['report_name_display'] == null ? undefined : json['report_name_display'],
        'beneficialOwners': json['beneficial_owners'] == null ? undefined : ((json['beneficial_owners'] as Array<any>).map(BeneficialOwnerFromJSON)),
        'companyApplicants': json['company_applicants'] == null ? undefined : ((json['company_applicants'] as Array<any>).map(CompanyApplicantFromJSON)),
        'typeOfFilingDisplay': json['type_of_filing_display'] == null ? undefined : json['type_of_filing_display'],
        'taxIdTypeDisplay': json['tax_id_type_display'] == null ? undefined : json['tax_id_type_display'],
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'updatedAt': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
        'reportName': json['report_name'] == null ? undefined : ReportNameEnumFromJSON(json['report_name']),
        'jurisdiction': json['jurisdiction'] == null ? undefined : JurisdictionEnumFromJSON(json['jurisdiction']),
        'filingStatus': json['filing_status'] == null ? undefined : FilingStatusEnumFromJSON(json['filing_status']),
        'paymentType': json['payment_type'] == null ? undefined : json['payment_type'],
        'paymentStatus': json['payment_status'] == null ? undefined : PaymentStatusEnumFromJSON(json['payment_status']),
        'receivedTimestamp': json['received_timestamp'] == null ? undefined : (new Date(json['received_timestamp'])),
        'filedDate': json['filed_date'] == null ? undefined : (new Date(json['filed_date'])),
        'approvedDate': json['approved_date'] == null ? undefined : (new Date(json['approved_date'])),
        'completedDate': json['completed_date'] == null ? undefined : (new Date(json['completed_date'])),
        'filingNote': json['filing_note'] == null ? undefined : json['filing_note'],
        'percentComplete': json['percent_complete'] == null ? undefined : json['percent_complete'],
        'emailNotificationStatus': json['email_notification_status'] == null ? undefined : json['email_notification_status'],
        'typeOfFiling': json['type_of_filing'] == null ? undefined : TypeOfFilingEnumFromJSON(json['type_of_filing']),
        'filingDate': json['filing_date'] == null ? undefined : (new Date(json['filing_date'])),
        'reportingCompanyLegalName': json['reporting_company_legal_name'] == null ? undefined : json['reporting_company_legal_name'],
        'alternateName': json['alternate_name'] == null ? undefined : json['alternate_name'],
        'foreignPooledInvestmentVehicle': json['foreign_pooled_investment_vehicle'] == null ? undefined : json['foreign_pooled_investment_vehicle'],
        'taxIdType': json['tax_id_type'] == null ? undefined : TaxIdTypeEnumFromJSON(json['tax_id_type']),
        'taxIdNumber': json['tax_id_number'] == null ? undefined : json['tax_id_number'],
        'taxIdCountry': json['tax_id_country'] == null ? undefined : json['tax_id_country'],
        'countryOfFormation': json['country_of_formation'] == null ? undefined : json['country_of_formation'],
        'stateOfFormation': json['state_of_formation'] == null ? undefined : json['state_of_formation'],
        'tribalJurisdiction': json['tribal_jurisdiction'] == null ? undefined : json['tribal_jurisdiction'],
        'nameOfTribe': json['name_of_tribe'] == null ? undefined : json['name_of_tribe'],
        'businessAddress1': json['business_address_1'] == null ? undefined : json['business_address_1'],
        'businessAddress2': json['business_address_2'] == null ? undefined : json['business_address_2'],
        'businessCity': json['business_city'] == null ? undefined : json['business_city'],
        'businessState': json['business_state'] == null ? undefined : json['business_state'],
        'businessZip': json['business_zip'] == null ? undefined : json['business_zip'],
        'businessCountry': json['business_country'] == null ? undefined : json['business_country'],
        'existingReportingCompany': json['existing_reporting_company'] == null ? undefined : json['existing_reporting_company'],
        'contactEmail': json['contact_email'] == null ? undefined : json['contact_email'],
        'submissionTrackingId': json['submission_tracking_id'] == null ? undefined : json['submission_tracking_id'],
        'boirId': json['boir_id'] == null ? undefined : json['boir_id'],
        'submissionTranscript': json['submission_transcript'] == null ? undefined : json['submission_transcript'],
        'team': json['team'] == null ? undefined : json['team'],
    };
}

export function PatchedBeneficialOwnershipReportToJSON(value?: Omit<PatchedBeneficialOwnershipReport, 'id'|'filing_status_display'|'jurisdiction_display'|'payment_status_display'|'report_name_display'|'type_of_filing_display'|'tax_id_type_display'|'created_at'|'updated_at'|'team'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'beneficial_owners': value['beneficialOwners'] == null ? undefined : ((value['beneficialOwners'] as Array<any>).map(BeneficialOwnerToJSON)),
        'company_applicants': value['companyApplicants'] == null ? undefined : ((value['companyApplicants'] as Array<any>).map(CompanyApplicantToJSON)),
        'report_name': ReportNameEnumToJSON(value['reportName']),
        'jurisdiction': JurisdictionEnumToJSON(value['jurisdiction']),
        'filing_status': FilingStatusEnumToJSON(value['filingStatus']),
        'payment_type': value['paymentType'],
        'payment_status': PaymentStatusEnumToJSON(value['paymentStatus']),
        'received_timestamp': value['receivedTimestamp'] == null ? undefined : ((value['receivedTimestamp'] as any).toISOString()),
        'filed_date': value['filedDate'] == null ? undefined : ((value['filedDate'] as any).toISOString().substring(0,10)),
        'approved_date': value['approvedDate'] == null ? undefined : ((value['approvedDate'] as any).toISOString().substring(0,10)),
        'completed_date': value['completedDate'] == null ? undefined : ((value['completedDate'] as any).toISOString().substring(0,10)),
        'filing_note': value['filingNote'],
        'percent_complete': value['percentComplete'],
        'email_notification_status': value['emailNotificationStatus'],
        'type_of_filing': TypeOfFilingEnumToJSON(value['typeOfFiling']),
        'filing_date': value['filingDate'] == null ? undefined : ((value['filingDate'] as any).toISOString().substring(0,10)),
        'reporting_company_legal_name': value['reportingCompanyLegalName'],
        'alternate_name': value['alternateName'],
        'foreign_pooled_investment_vehicle': value['foreignPooledInvestmentVehicle'],
        'tax_id_type': TaxIdTypeEnumToJSON(value['taxIdType']),
        'tax_id_number': value['taxIdNumber'],
        'tax_id_country': value['taxIdCountry'],
        'country_of_formation': value['countryOfFormation'],
        'state_of_formation': value['stateOfFormation'],
        'tribal_jurisdiction': value['tribalJurisdiction'],
        'name_of_tribe': value['nameOfTribe'],
        'business_address_1': value['businessAddress1'],
        'business_address_2': value['businessAddress2'],
        'business_city': value['businessCity'],
        'business_state': value['businessState'],
        'business_zip': value['businessZip'],
        'business_country': value['businessCountry'],
        'existing_reporting_company': value['existingReportingCompany'],
        'contact_email': value['contactEmail'],
        'submission_tracking_id': value['submissionTrackingId'],
        'boir_id': value['boirId'],
        'submission_transcript': value['submissionTranscript'],
    };
}

